/* eslint-disable no-unused-vars */
const state = {
  token: null,
  info: {
    userId: null,
  },
};

const getters = {
  token: (state) => state.token,
  userId: (state) => state.info.userId,
  isAuthenticated: (state) => !!state.token,
};

const actions = {

  async LogIn({ commit }, User) {
    const response = await window.axios.post("teller/login", User);
    response.data["content"]["userId"] = User.get("userId");
    await commit("setUser", response.data);
  },

  async CashIn({ commit, state }, SendForm) {
    return await window.axios.post("wallets/transactions", SendForm, {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
  },

   async CashOut({ commit, state }, SendForm) {
    return await window.axios.post("wallets/transactions", SendForm, {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    
  },

  async LogOut({ commit }) {
    const user = null;
    commit("LogOut", user);
  },
};

const mutations = {
  setUser(state, response) {
    state.token = response.content.access_token;
    state.info.userId = response.content.userId;
  },

  LogOut(state) {
    state.info.userId = null;
    state.token = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
