<template>
  <div>
    <a-modal
      v-model="visible"
      title="PIN"
      :closable="true"
    >
      <p>User registered successfully.</p>
    </a-modal>
    <a-form-model
      @submit.prevent="submit"
      :layout="'vertical'"
      class="vertical-auto-i"
    >
      <div class="max-w-md px-5">
        <div class="grid grid-cols-1 mt-5">
          <div class="text-xl font-bold login-title">Create User</div>
          <a-form-model-item
            :label="'Name'"
            class="text-center"
            :validate-status="_.anyErrors('name', errors)"
            :help="_.getErrorMessage('name', errors)"
          >
            <a-input
              type="text"
              v-model="form.name"
              class="ant-input name-input"
              placeholder=""
            />
          </a-form-model-item>

           <a-form-model-item
            :label="'Email'"
            class="text-center"
            :validate-status="_.anyErrors('email', errors)"
            :help="_.getErrorMessage('email', errors)"
          >
            <a-input
              type="email"
              v-model="form.email"
              class="ant-input email-input"
              placeholder=""
            />
          </a-form-model-item>

          <a-form-model-item
            :label="'Password'"
            class="text-center"
            :validate-status="_.anyErrors('password', errors)"
            :help="_.getErrorMessage('password', errors)"
          >
            <a-input
              type="password"
              v-model="form.password"
              class="ant-input last-input"
              placeholder=""
            />
          </a-form-model-item>
      
          <div class="mb-5 submit">
            <button
              class="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded  hover:bg-blue-700"
            >
              REGISTER
            </button>
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Register",
  components: {
  },
  data() {
    return {
      errors: [],
      visible: false,
      form: {
        email: "denroy@test.com",
        name: "Denroy",
        password: "123456",
      },
    };
  },
  methods: {
    ...mapActions(["CreateUser"]),
    
    showModal() {
      this.visible = true;
    },
 
    async submit() {
      const User = new FormData();
      User.append("email", this.form.email);
      User.append("name", this.form.name);
      User.append("password", this.form.password);

      try {
        await this.CreateUser(User);
        this.showModal();
        this.showError = false;
      } catch (err) {
        let error = this._.getError(err);
        if (error.is_error_bag) {
          this.errors = error.content;
        } else {
          this.$message.error(error);
        }
      }
    },
  },
};
</script>
