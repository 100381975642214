<template>
  <div>
    <div class="flex flex-col max-w-md px-5 mt-5">
      <div class="text-xl font-bold home-title">Welcome</div>
      <div class="grid flex-1 grid-cols-1 gap-6 mt-5">
        
        <button
          @click="goToCashIn"
          class="px-4 py-2 font-semibold text-white bg-blue-500 border border-blue-500 rounded  hover:bg-blue-700 hover:text-white hover:border-transparent"
        >
          Cash In
        </button>
        
        <button
          @click="goToCashOut"
          class="px-4 py-2 font-semibold text-white bg-blue-500 border border-blue-500 rounded  hover:bg-blue-700 hover:text-white hover:border-transparent"
        >
          Cash Out
        </button>
        <button
          @click="goToTransactions"
          class="px-4 py-2 font-semibold text-white bg-blue-500 border border-blue-500 rounded  hover:bg-blue-700 hover:text-white hover:border-transparent"
        >
          Transactions
        </button>

        <div class="justify-center inset-x-0 bottom-0  mb-5">
            <div class="max-w-md ">
               <button
                @click="logout"
                class="w-full   py-2 font-semibold text-white bg-red-500 border border-red-500 rounded  hover:bg-red-700 hover:text-white hover:border-transparent"
              >
                LOGOUT
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  methods: {
   
    goToCashIn() {
      this.$router.push("/cash-in");
    },

    goToCashOut() {
      this.$router.push("/cash-out");
    },
   
    goToTransactions() {
      this.$router.push("/transactions");
    },

    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
</style>
