import Vue from "vue";
import Vuex from "vuex";
import cbdc from "./modules/cbdc";
import * as loading from "./modules/Loading";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cbdc,
    loading,
  },
  plugins: [createPersistedState()],
});
