export const namespaced = true;

export const state = {
  fullPage: false,
  canCancel: false,
  onCancel: null,
  doLoading: false,
};

export const getters = {
  isLoading: (state) => state.doLoading,
  isCanCancel: (state) => state.canCancel,
  isFullPage: (state) => state.fullPage,
  onCancel: (state) => state.onCancel,
};

export const actions = {};

export const mutations = {
  setLoading(state, response) {
    state.doLoading = response;
  },

  setCanCancel(state, response) {
    state.canCancel = response;
  },

  setOnCancel(state, response) {
    state.onCancel = response;
  },

  setFullPage(state, response) {
    state.fullPage = response;
  },
};
