<template>
  <div>
    <loading
      :active.sync="isLoading"
      :can-cancel="isCanCancel"
      :on-cancel="onCancel"
      :is-full-page="isFullPage"
      :color="'#3b82f6'"
      style="z-index: 99999"
    />
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "App",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters("loading", [
      "isLoading",
      "isCanCancel",
      "isFullPage",
      "onCancel",
    ]),
  },
};
</script>
<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: -moz-center;
  text-align: -webkit-center;
  color: #2c3e50;
  background-color: #f0f9ff !important;
}
</style>
