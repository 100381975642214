/* eslint-disable no-undef */
export const getError = (error) => {
  /* eslint-disable no-console */
  const errorMessage = "API Error, please try again.";

  if (!error.response) {
    return errorMessage;
  }
  if (error.response.data.errors) {
    return error.response.data.errors;
  }

  if (error.name === "Fetch User") {
    return error.message;
  }

  if (!error.response) {
    return errorMessage;
  }
  // eslint-disable-next-line no-empty
  if (process.env.NODE_ENV === "development") {
  }
  if (error.response.data && error.response.data.errors) {
    //TODO Handle this better
    if (_.keys(error.response.data.errors).length > 0) {
      return {
        is_error_bag: true,
        content: error.response.data.errors,
      };
    }
    return error.response.data.message;
    //return error.response.data.errors;
  }

  if (error.response.data && !error.response.data.success) {
    if (!error.response.data.success && error.response.data.is_error_bag)
      return error.response.data;
    return error.response.data.message;
  }
  /* eslint-enable no-console */
  return errorMessage;
};

export const getErrorMessage = (key, errorBag, showKey = false) => {
  if (anyErrors(key, errorBag)) {
    let temp = errorBag[key][0];
    if (!showKey) {
      temp = temp.replace(key, "");
      let words = temp.split(" ");
      words.splice(0, 1);
      temp = words.join(" ").trim();
      temp = temp.charAt(0).toUpperCase() + temp.slice(1);
    }
    return temp;
  }
  return "";
};

export const anyErrors = (key, errorBag) => {
  return errorBag &&
    _.keys(errorBag).length > 0 &&
    errorBag[key] &&
    errorBag[key].length > 0
    ? "error"
    : "";
};

export const getBoolean = (value) => {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
};

export const isBlank = (value) => {
  return (_.isEmpty(value) && !_.isNumber(value)) || _.isNaN(value);
};
