import Vue from "vue";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  getError,
  getErrorMessage,
  anyErrors,
  getBoolean,
  isBlank,
} from "@/utils/helpers";
import "@/assets/styles/main.css";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(VueLodash, { lodash: lodash });

// eslint-disable-next-line no-undef
_.mixin({ isBlank, getErrorMessage, anyErrors, getError, getBoolean });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
