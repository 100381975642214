<template>
  <div>
    <a-form-model @submit.prevent="submit" :layout="'vertical'">
      <div class="max-w-md px-5">
        <div class="grid grid-cols-1 mt-5">
          <div class="text-xl font-bold login-title">Teller Portal - SIGN IN</div>
          <a-form-model-item
            :label="'Email'"
            class="mt-10 text-center"
            :validate-status="_.anyErrors('email', errors)"
            :help="_.getErrorMessage('email', errors)"
           
          >
            <input
              type="text"
              v-model="form.email" class="ant-input email-input"
               value=""
            />
          </a-form-model-item>
          <a-form-model-item
            :label="'Password'"
            class="text-center"
            :validate-status="_.anyErrors('password', errors)"
            :help="_.getErrorMessage('password', errors)"
          >
            <a-input-password
              v-model="form.password"
              class="password-input"

            />
          </a-form-model-item>
          <div class="inset-x-0 bottom-0   mb-5 submit">
            <button
              class="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded  hover:bg-blue-700"
            >
              LOGIN
            </button>
          </div>
             <div class="inset-x-0 bottom-0   mb-5 submit">
            <button
              class=" px-4 py-2  text-black"
            >
              Forget password
            </button>
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      errors: [],
      form: {
        email: "denroy@test.com",
        password: "123456",
      },
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("email", this.form.email);
      User.append("password", this.form.password);
      try {
        await this.LogIn(User);
        this.$router.push("/");
        this.showError = false;
      } catch (err) {
        console.log(err);
        let error = this._.getError(err);
        console.log(error);
        if (error.is_error_bag) {
          this.errors = error.content;
        } else {
          this.$message.error(error);
        }
      }
    },
  },
};
</script>