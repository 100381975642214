<template>
  <div>
    <div>
      <div class="grid grid-cols-1 gap-6 px-5 pt-5">
        <div class="text-xl font-bold transaction-title">Transactions</div>
        <form class="w-full max-w-sm transaction-form">
          <div class="flex items-center py-2 border-b border-teal-500">
            <input
              v-model="search_value"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm  search-value-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              type="text"
              placeholder="SEARCH"
            />
          </div>
          <select
            v-model="selected_category"
            class="
              block
              w-full
              mt-0
              px-0.5
              border-0 border-b-2 border-gray-200
              focus:ring-0 focus:border-black
            "
          >
            <option>AMOUNT</option>
            <option>ACCOUNT #</option>
            <option>TYPE</option>
            <option>DATE</option>
          </select>
        </form>
        <div class="w-full scrolling-box">
          <a-card
            v-for="(item, index) in transactions.data"
            v-bind:key="index"
            class="px-0 my-2"
          >
            <div class="grid grid-cols-3">
              <div class="col-span-2">
                <p
                  class="px-0 mb-0 font-semibold text-left text-light-blue-600"
                >
                  {{ item.type }}
                  {{ item.type === "SEND" ? "to" : "from" }} account number
                  {{ item.to_account_number }}
                </p>
              </div>
              <div>
                <p
                  class="px-0 mb-0 font-semibold text-right text-light-blue-600"
                >
                  ${{ item.amount }}
                </p>
              </div>
            </div>
            <p class="px-0 pt-2 mb-0 font-medium text-left text-light-blue-600">
              {{ item.date }}
            </p>
          </a-card>
        </div>
      </div>
    </div>
    <div
      class="fixed inset-x-0 bottom-0 px-5 mb-5"
      style="background-color: #f0f9ff"
    >
      <vue-pagination
        :pagination="transactions"
        @paginate="getTransactions()"
        :offset="4"
      >
      </vue-pagination>
      <div>
        <button
          @click="search"
          class="w-full px-4 py-2 font-semibold text-white bg-green-500 border border-green-500 rounded  hover:bg-green-500 hover:text-white hover:border-transparent"
        >
          SEARCH
        </button>
      </div>
      <div class="mt-5">
        <button
          @click="$router.back()"
          class="w-full px-4 py-2 font-semibold text-white bg-red-500 border border-red-500 rounded  hover:bg-red-700 hover:text-white hover:border-transparent"
        >
          BACK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VuePagination from "../common/Pagination.vue";

export default {
  components: {
    VuePagination,
  },
  data() {
    return {
      transactions: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1,
      },
      search_value: "",
      selected_category: "AMOUNT",
      offset: 4,
    };
  },
  methods: {
    ...mapActions(["GetHistory"]),
    search() {
      this.transactions.current_page = 1;
      this.getTransactions();
    },
    async getTransactions() {
      try {
        this.sentOff = true;
        this.transactions = await this.GetHistory({
          category: this.selected_category,
          search_value: this.search_value,
          current_page: this.transactions.current_page,
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
  async mounted() {
    this.search();
  },
};
</script>

<style scoped>
.transaction-form {
  margin-right: auto;
  margin-left: auto;
}

.scrolling-box {
  display: block;
  height: 100vh;
  max-height: 50vh;
  overflow-y: scroll;
  text-align: center;
}
</style>
